@use '../custom' as *;
@use '../components/mixins' as *;

#section-header {

    .authBtn a{
      color: $gray-800 !important;
      
    }

    .logo {
        width: 120px;
        height: auto;

        @include media-breakpoint-down(lg) {
            width: 100px;
        }
    }

    .nav-item:hover {
        border-bottom: 1px solid $info;
        
        @include media-breakpoint-down(lg) {
            border-bottom: none !important;
        }

    }

}

#homepage {
    // height: 100vh;
    padding-top: 10rem;
}

#section-contact {
    margin-top: -11rem;
    padding-top: 3rem;

    @include media-breakpoint-down(lg) {
        margin-top: -6rem;
        padding-top: 3rem;
    }
    @include media-breakpoint-down(sm) {
        margin-top: -3rem;
        padding-top: 2rem;
    }
    
}



#section-values {
    .values-column {
        padding: 2% 2%;
    }
}

#section-vacancies {
    

    .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
        // color: #fff;
        // background-color: #4a9aca;
    }
    .nav-pills .nav-link {
        // color: $gray-800 !important;
        width: max-content;

    }

    @include media-breakpoint-down(lg) {
        // margin-top: 30rem;
        padding-bottom: 5.7rem !important;
    }
    
}

.top-wave {
    margin-left: -0.72rem;
    margin-right: -0.72rem;

    @include media-breakpoint-down(lg) {
        padding-top: 3.3rem;
    }
}

#hero-header {
    position: relative;
    height: 100vh;
    background:
    /* top, transparent */ 
    linear-gradient(
      rgba(17, 17, 17, 0.7), 
      rgba(0, 0, 0, 0.5)
    ),
    /* bottom, image */
    url("../img/header_1-min.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    
    
    @include media-breakpoint-down(lg) {
        background-attachment: scroll;
    }


    .header__text-box {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;

        @include media-breakpoint-down(lg) {
             top: 50%;
        }
    }

    .header__text-box--slogan {
        @include media-breakpoint-down(lg) {
            font-size: 0.8rem;
        }

    }


    .btn-about {
        padding: 1rem 2rem !important;
        font-size: 1.5rem !important;
        -webkit-backface-visibility: hidden;
        box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
        -webkit-animation: pulse 1.5s infinite;

        @include media-breakpoint-down(lg) {
            padding: 0.5rem 0.8rem !important;
            font-size: 0.9rem !important;
        }

        &:hover {
            -webkit-animation: none;
        }

        @-webkit-keyframes pulse {
            0% {
              transform: scale(.9);
            }
            70% {
              transform: scale(1);
              box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
            }
              100% {
              transform: scale(.9);
              box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
            }
        }

        
    }

    .heading-primary--main {
        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-out;
    }
    
    .heading-primary--sub {
        animation: moveInRight 1s ease-out;
    }
    
    .heading-primary--slogan {
        animation-name: moveInLeft;
        animation-duration: 3s;
        animation-timing-function: ease-out;
    }
    
    @keyframes moveInLeft {
        0% {
            opacity: 0;
            transform: translateX(-10rem);
        }

        80% {
            transform: translateX(1rem);
        }

        100% {
            opacity: 1;
            transform: translate(0);
        }
    }

    @keyframes moveInRight {
        0% {
            opacity: 0;
            transform: translateX(10rem);
        }

        80% {
            transform: translateX(-1rem);
        }

        100% {
            opacity: 1;
            transform: translate(0);
        }
    }

    @keyframes moveInBottom {
        0% {
            opacity: 0;
            transform: translateY(20rem);
        }

        80% {
            transform: translateY(-4rem);
        }

        100% {
            opacity: 1;
            transform: translate(0);
        }
    }
}

#section-about {
    min-height: 100vh;
    margin-top: -4rem;
    // padding: 0 !important;
    @include media-breakpoint-down(lg) {
        margin-top: 0;
    }

    

    .composition {
        position: relative;
        // width: 100%;
        // height: 100%;
        margin-top: 3.5rem;

        @include media-breakpoint-down(lg) {
            margin-top: 3rem;
        }
    }
    
    .composition__photo {
        position: absolute;
        width: 55%;
        box-shadow: 0 8px 17px rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        z-index: 10;
        transition: all .5s;
        outline-offset: .1rem;
        
        
        @include media-breakpoint-down(lg) {
            // width: 60%;
            // width: 15rem;
        }
    }
    
    .composition__photo--p1 {
        left: 0;
        top: -2rem;
    }
    
    .composition__photo--p2 {
        right: 0;
        top: 2rem;
    }
    
    .composition__photo--p3 {
        left: 20%;
        top: 10rem;

        @include media-breakpoint-down(lg) {
            // left: 14%;
        }
    }
    
    .composition__photo:hover {
        outline-style: groove;
        transform: scale(1.05) translateY(-0.5rem);
        box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
        z-index: 20;
    }
    
    .composition:hover .composition__photo:not(:hover) {
        transform: scale(0.95);
        opacity: .5;
        transition: transform .7s, opacity 0.10s;
    }

}

#cookies-details.modal {
    z-index: 2001;
}

.heading-secondary {
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to right, #0097e6, #e1b12c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0.2rem;
    transition: all 0.2s;
    font-size: 3.5rem;
    
    @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
    }
}

.heading-secondary:hover {
    transform: scale(1.1);
    text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}