@use '../custom' as *;
@use '../components/mixins' as *;


#section-services {

    .service-intro--text {
        width: 80%;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

    }

    @include media-breakpoint-down(lg) {
        @include mobilePadding;
    }

    .service-column {
        padding: 2% 2%;
    }
    
    .service-text {
        font-size: 2rem;
        line-height: 1.5;
        
        @include media-breakpoint-down(lg) {
            font-size: 1.5rem;
        }
    }
    

    .card-heading {
        background-color: $primary;
        color: $white;
        text-align: center;
    }
    
    .card-heading h3{
        margin-bottom: 0 !important;
    }

    @include media-breakpoint-down(md) {
        margin-top: 20rem;
    }

}