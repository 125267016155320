@use '../custom' as *;
@use '../components/mixins' as *;

.footer-section {
    
    
}


.footer-section-mobile {


    .bs-icon,
    .bs-icon-text {
        color: $gray-800;
    }

    .bs-icon {
        font-size: 1.2rem;
    }
    
    .copyright-text,
    .bs-icon-text {
        font-size: 0.9rem;
    }

    .dock {
        padding: 0.3rem 0;
    }
    .dock a, a:active, a:visited {
        color: $gray-800;
    }

    .contactDetails {
        display: none;
        opacity: 0;
    }
    .contactDetails.show {
        display: block;
        opacity: 1;
        transition: all 2s ease-in-out;
    }

}

.bsIconPhone {
    color: var(--bs-green);
    border: 1px solid var(--bs-green) !important;
    padding: 5px;
    border-radius: 3px;
}

.shadowTop {
    border-top: 1px solid rgba(0, 0, 0, 0.124);
    // box-shadow: 0 -9px 4px -10px rgba(0, 0, 0, 0.439);
}

.preferred-email,
.preferred-telephone {
    cursor: pointer;
}
.preferred-email:hover,
.preferred-telephone:hover {
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
}

.bs-icon-preferred {
    font-size: 2rem;;
}