@use '../custom' as *;
@use '../components/mixins' as *;

html, body {
    height: 100%;
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

.mt-n5 {
    margin-top: -5rem !important;

    @include media-breakpoint-down(lg) {
        margin-top: 0 !important;
    }
}

.pointer {
    cursor: pointer;
}

// .cqc-rating {
//     th, td {
//         white-space: nowrap;
//     }
// }

a, a:active, a:visited {
    // color: $gray-800;
    text-decoration: none;
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.modal-content {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

h1:after {
    content: "";
    width: 20rem;
    border: 1.5px solid $gray-600;
    border-radius: 0.5rem;
    display: block;
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
        width: 8rem;
    }
}

// Button modifier
.btn {
    border-radius: 0;
    font-size: 1rem;
    padding: 0.4rem !important;
    box-shadow: inset 1px 1px 10px rgba(51, 51, 51, 0.234);
    @extend %btn-inset-shadow;
}

.heading {
    font-family: 'Lato', sans-serif;
    text-transform: uppercase;

}

.letter-spacer-sm {
    letter-spacing: 0.3rem;
}


/* Bootstrap icon sizing and color*/
/* Shopping Cart*/
.bi {
    width: 1em;
    font-size: 1.2rem;
}
.bs-icon-lg {
    font-size: 2rem;
    color: $primary;
}

/* ******************************** */
/* Placeholder and Form Styles */
/* ******************************** */
::placeholder {
    font-style: italic;
    color: rgba(0, 0, 0, 0.3) !important;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
    color: transparent !important;
    transition: all 2s ease;
}

input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
    color: transparent !important;
    transition: all 2s ease;
}



/* ************************************************************************* */
/* REMOVE BUTTON SHADOW AND INPUT SHADOWS. NO MORE OUTLINES IN BOOTSTRAP 5.X */
/* ************************************************************************* */
.form-select:focus,
.dropdown-toggle:active,
.btn-close:focus,
.form-check-input:focus,
.form-control:focus,
.btn:focus,.btn:active:focus,.btn.active:focus {
    // outline: none;
    -webkit-box-shadow:none !important;
    box-shadow: none !important;
}

/* **************************** */
/* SUCCESS AND ERROR STYLES */
/* **************************** */
.alert--success {
    background-color: $success;
}

.alert--error {
    background-color: $warning;
    // color: $white;
}
.error {
    background-color: $warning;
    color: $black;
    padding: 3px;
}

.clientSideAlerts,
#catchErrors,
#success {
    text-align: center;
    margin: 0 auto;
    z-index: 2000;
}

.alert {
    @include media-breakpoint-down(lg) {
        width: 100% !important;
    }
}

#backToTop {
    visibility: hidden;
    position: fixed;
    right: 0.5rem;
    bottom: 5.5rem;
    opacity: 0;
    z-index: 1005;
    transition: all 1s ease-in;
  
    @include media-breakpoint-down(lg) {
      bottom: 7.5rem;
    }
  }
  
  #backToTop.show {
    visibility: visible;
    opacity: 1;
  }

// Terms and Cookies
.terms-and-cookies-notice {
    font-size: 0.8rem;
}

/* Cookie Notice */
.gdprRemove {
    display: none;
  }
  
.gdpr {
position: fixed !important;
// border: 1px solid rgb(149, 149, 149) !important;
left: 10% !important;
width: 80% !important;
z-index: 2000 !important;
color: rgb(255, 255, 255) !important;
text-shadow: none !important;
background: rgb(0, 0, 0) none repeat scroll 0% 0% !important;
margin: 0px;
box-shadow: 0 0.1em 0.2em $gray-300 !important;
opacity: 0.9;
}

.overLay {
position: fixed;
width: 100%;
height: 100%;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5);
z-index: 1999;
cursor: pointer;
}


.gdpr p {
width: 95%;
}

.gdpr p,
.gdpr h2 {
margin: 15px 35px 10px 35px !important;
text-align: left !important;
font: 10pt Verdana !important;

}

@include media-breakpoint-down(lg) {
    .gdpr {
        left: 0 !important;
        width: 100% !important;
    }

    .gdpr p,
    .gdpr h2 {
        margin: 0 !important;
        padding: 10px !important;
    }
}

#js__close__gdpr {
    cursor: pointer;
}

#appContainer {
    scroll-snap-type: mandatory;
}

#section-about, #section-services, #section-values, #section-contact, #section-vacancies {
    padding-top: 55px;
    padding-bottom: 40px;
    scroll-snap-align: start;
}

// TOOL TIP MODIFIER
.tooltip .tooltip-inner {background-color: $info; color: $dark;} 
.bs-tooltip-top .tooltip-arrow::before {border-top-color: $info !important;}
.bs-tooltip-end .tooltip-arrow::before {border-right-color: $info !important;}
.bs-tooltip-bottom .tooltip-arrow::before {border-bottom-color: $info !important;}
.bs-tooltip-start .tooltip-arrow::before {border-left-color: $info !important;}