@use '../custom' as *;
@use '../components/mixins' as *;

#section-features {

    @include media-breakpoint-down(lg) {
        @include mobilePadding; 
    }

    // .featuresList {
    //     line-height: 1.2 !important;
    //     font-size: 1rem !important;
    // }
}